<template>
	<footer
		v-if="footerExists"
		id="footer"
		class="c-page-footer print:hidden bg-primary-button"
	>
		<div
			:class="[
				'w-full p-layout-margin',
				'grid grid-cols-1 gap-y-layout-margin',
				'>=1024:grid-cols-2 >=1024:gap-x-layout-gutter >=1024:gap-y-0',
			]"
		>
			<div
				:class="[
					'flex flex-col space-y-md justify-center',
					'>=1024:flex-row >=1024:space-y-0 >=1024:space-x-xl',
					'flex-1 bg-white p-md rounded-md',
				]"
			>
				<!-- Address -->
				<div
					v-if="address"
					class="flex flex-col h-full justify-between"
				>
					<div>
						<BaseH3
							tag="h2"
							class="mb-sm >=1024:mb-md"
							v-text="address.title"
						/>

						<div class="space-y-xs >=1024:mb-md">
							<a
								v-if="
									address.street ||
									address.postalCode ||
									address.city
								"
								:href="computedAddressMapsUrl"
								target="_blank"
								class="
									flex
									space-x-xs
									items-start
									cursor-pointer
									hover:underline
								"
							>
								<SvgLocation class="w-xs mt-4" />

								<span>
									{{ address.street }}<br />
									{{ address.postalCode }}
									{{ address.city }}
								</span>
							</a>

							<a
								v-if="address.phone"
								class="
									flex
									space-x-xs
									items-start
									hover:underline
								"
								:href="`tel:${address.phone}`"
							>
								<SvgPhone class="w-xs mt-4" />
								<span v-text="address.phone"></span>
							</a>

							<a
								v-if="address.email"
								class="
									flex
									space-x-xs
									items-start
									hover:underline
								"
								:href="`mailto:${address.email}`"
							>
								<SvgMail class="w-xs mt-4" />
								<span v-text="address.email"></span>
							</a>
						</div>
					</div>

					<span
						class="hidden >=1024:block"
						v-text="`CVR. ${address.cvr}`"
					></span>
					<NuxtLinkExt
						v-if="isLoggedIn"
						class="hidden >=1024:block hover:underline"
						:to="`${authenticatorRequest.backendUrl}/umbraco/#/content/content/edit/${pageId}`"
						target="_blank"
					>
						<span>Redakt&oslash;rlogin</span>
					</NuxtLinkExt>
				</div>

				<!-- Contact -->
				<div
					v-if="contact"
					class="flex flex-col justify-between flex-1"
				>
					<div>
						<BaseH3
							id="footer-contact"
							tag="h2"
							class="mb-sm >=1024:mb-md"
							v-text="contact.title"
						/>

						<div class="space-y-xs">
							<ContentLink
								v-for="(link, index) in computedContact"
								:key="`contact-${index}`"
								:to="link.url"
								:target="link.target"
								aria-describedby="footer-contact"
								class="block"
							>
								{{ link.name }}
							</ContentLink>
						</div>
					</div>

					<span
						class=">=1024:hidden mt-md"
						v-text="`CVR. ${address.cvr}`"
					></span>
					<NuxtLinkExt
						v-if="isLoggedIn"
						class=">=1024:hidden mt-md hover:underline"
						:to="`${authenticatorRequest.backendUrl}/umbraco/#/content/content/edit/${pageId}`"
						target="_blank"
					>
						<span>Redakt&oslash;rlogin</span>
					</NuxtLinkExt>

					<div class="flex flex-wrap gap-x-12 gap-y-12 mt-md">
						<div
							v-for="(link, index) in computedSoMe"
							:key="`some-${index}`"
							:class="link.url ? '' : 'hidden'"
						>
							<NuxtLinkExt
								v-if="link.url"
								:to="link.url"
								target="_blank"
								:class="[
									'block text-primary-button hover:text-primary-button-hover',
									'duration-500 ease-smooth-out',
								]"
								:aria-label="link.label"
							>
								<Component :is="link.icon" class="w-32" />
							</NuxtLinkExt>
						</div>
					</div>
				</div>
			</div>

			<!-- Right Container -->
			<div class="flex-1 bg-white p-md rounded-md">
				<div
					v-if="seeAlso"
					class="flex flex-col justify-between h-full"
				>
					<div>
						<BaseH3
							tag="h2"
							class="mb-sm >=1024:mb-md"
							v-text="seeAlso.title"
						/>

						<div
							v-if="seeAlso.links"
							:class="[
								'grid grid-cols-1 >=1024:grid-cols-2',
								'gap-y-xs gap-x-sm max-w-864',
							]"
						>
							<ContentLink
								v-for="(link, index) in seeAlso.links"
								:key="`seealso-${index}`"
								:to="link.url"
								:target="link.target"
							>
								{{ link.name }}
							</ContentLink>
							<ContentLink
								to="https://slagelse.dk/was"
								target="_blank"
							>
								Tilgængelighedserklæring
							</ContentLink>
						</div>
					</div>

					<div v-if="seeAlso.readOutLoud" class="mt-md">
						<NuxtLinkExt
							class="inline-block"
							:to="seeAlso.readOutLoud.url"
							:target="seeAlso.readOutLoud.target"
						>
							<BaseButton tag="div" type="secondary">
								<template #icon>
									<SvgReadLoud class="w-16 h-16" />
								</template>
								<template #default>Læs Højt</template>
							</BaseButton>
						</NuxtLinkExt>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import ContentLink from '~/components/shared/ContentLink';
import SvgLocation from '~/assets/svgs/location-icon.svg?inline';
import SvgPhone from '~/assets/svgs/phone-icon.svg?inline';
import SvgMail from '~/assets/svgs/mail-icon.svg?inline';
import SvgFacebook from '~/assets/svgs/facebook-icon.svg?inline';
import SvgTwitter from '~/assets/svgs/twitter-x-icon.svg?inline';
import SvgInstagram from '~/assets/svgs/instagram-icon.svg?inline';
import SvgYoutube from '~/assets/svgs/youtube-icon.svg?inline';
import SvgLinkedIn from '~/assets/svgs/linkedin-icon.svg?inline';
import SvgReadLoud from '~/assets/svgs/read-loud-icon.svg?inline';

export default {
	name: 'PageFooter',

	components: {
		ContentLink,
		SvgLocation,
		SvgPhone,
		SvgMail,
		SvgFacebook,
		SvgTwitter,
		SvgInstagram,
		SvgYoutube,
		SvgLinkedIn,
		SvgReadLoud,
	},

	data() {
		const data = this.$store.state.site?.footer;
		const soMeItems = this.$store.state.site?.soMeItems;
		const authenticatorRequest =
			this.$store.state.site?.authenticatorRequest || {};

		return {
			footerExists: !!data,
			...data,
			soMeItems,
			authenticatorRequest,
			isLoggedIn: null,
			pageId: null,
		};
	},

	computed: {
		computedContact() {
			const { openingHours, book } = this.contact;
			const response = [];

			openingHours && response.push(openingHours);
			book && response.push(book);
			return response;
		},
		computedAddressMapsUrl() {
			const addressParts = [
				this.address.street,
				this.address.postalCode,
				this.address.city,
			];
			const filteredAddress = addressParts.filter(Boolean).join(' ');
			const encodedAddress = encodeURIComponent(filteredAddress);
			return `https://www.google.com/maps?q=${encodedAddress}`;
		},
		computedSoMe() {
			const someMap = {
				facebookUrl: {
					icon: 'SvgFacebook',
					label: 'Gå til Slagelses Facebook-side',
				},
				instagramUrl: {
					icon: 'SvgInstagram',
					label: 'Gå til Slagelses Instagram-side',
				},
				linkedInUrl: {
					icon: 'SvgLinkedIn',
					label: 'Gå til Slagelses LinkedIn-side',
				},
				twitterUrl: {
					icon: 'SvgTwitter',
					label: 'Gå til Slagelses Twitter-side',
				},
				youTubeUrl: {
					icon: 'SvgYoutube',
					label: 'Gå til Slagelses YouTube-side',
				},
			};

			return Object.keys(this.soMeItems).map((key) => ({
				url: this.soMeItems[key],
				...someMap[key],
			}));
		},
	},

	watch: {
		'$route.path': {
			handler() {
				this.pageId = this.$store.state?.pageId;
				this.getEditorLink();
			},
		},
	},

	mounted() {
		this.pageId = this.$store.state?.pageId;
		this.getEditorLink();
	},

	methods: {
		getEditorLink() {
			this.$axios
				.get(this.authenticatorRequest.url, { withCredentials: true })
				.then((response) => {
					response && (this.isLoggedIn = response);
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>

<style lang="postcss">
.c-page-footer {
	@apply transition duration-300 ease-in-out;
}

.js-page-transition:not(.js-page-transition--enter)
	*[class^='l-']:not(.l-error)
	*[class^='d-']:not(.d-error-page)
	.c-page-footer {
	@apply opacity-0;
}

/**
	Forces white background on text,
	because SiteImprove is weird.
 */
.c-page-footer a,
.c-page-footer p,
.c-page-footer span,
.c-page-footer h2 {
	background-color: #ffffff !important;
}
</style>
